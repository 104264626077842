:root {
  --white: #ffffff;
  --primary: #1f71f4;
  --padding-layout: 16px;
  --spacing: 6px;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter" !important;
}

.App {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.title-medium {
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.layout-topbar {
  padding: var(--padding-layout);
  position: sticky;
  top: 0;
  width: 100%;
  background-color: var(--white);
  z-index: 99;
}

.layout-content {
  height: calc(100vh - 68px - 104px);
  overflow: hidden;
}

.layout-topbar-menu a {
  color: #2567b2;
  font-size: 13px;
  text-transform: uppercase;
  font-weight: bold;
}

.layout-tra-cuu {
  background-image: linear-gradient(to bottom right, #354aba, #44bbd1);
  padding: 60px 0;
}

.layout-loi-ich > h2 {
  font-size: 1.75rem;
  font-weight: 500;
  color: #2567b2;
}

.layout-loi-ich .item {
  border: 1px solid #2567b2;
  border-radius: 10px;
  padding: 10px 30px;
  margin: 10px 0;
}

img {
  max-width: 100%;
}

.item .item-img {
  width: 140px;
  margin: 0 auto;
}

.item .item-title {
  text-transform: uppercase;
  font-weight: 700;
  color: #2567b2;
}

.item .item-text {
  color: #8a8a8a;
  font-size: 15px;
  height: 60px;
}

.layout-footer {
  background-color: var(--primary);
  padding: var(--padding-layout);
  color: var(--white);
  text-align: center;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  gap: var(--spacing);
  position: sticky;
  bottom: 0px;
}

.text-secondary {
  font-size: 0.75rem;
  line-height: 1rem;
}

.box-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-dialog .p-dialog-header {
  padding: 0.5rem 1rem;
}

.p-dialog .p-dialog-content {
  padding: 0 1rem 1rem 1rem;
}

.w-900 {
  max-width: 900px;
  width: 100%;
}
