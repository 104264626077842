$background: linear-gradient(to bottom right, #354aba, #44bbd1);
$green-primary: #22c55e;
$blue-primary: #2568b2;
$white: #fff;
$padding-layout: 16px;
$shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.03), 0 1px 6px -1px rgba(0, 0, 0, 0.02),
  0 2px 4px 0 rgba(0, 0, 0, 0.02);
$border: 1px solid #f0f0f0;

.invoicePage {
  overflow-y: auto;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  .invoiceSearch {
    width: 100%;
    // background-image: $background;
    background-color: #1f71f4;
    padding: $padding-layout;
    display: flex;
    font-weight: 500;
    justify-content: center;
    align-items: center;
    .boxSearch {
      max-width: 568px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 24px;
      .title {
        color: $white;
        font-size: 2rem;
        text-align: center;
        line-height: 34px;
      }
      .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: $padding-layout;
        .search {
          width: 100%;
        }
      }
    }
  }
  .benefit {
    width: 100%;
    text-align: center;
    padding: $padding-layout;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      text-transform: uppercase;
      color: $blue-primary;
      font-size: 1.3rem;
      text-align: center;
      line-height: 34px;
      font-weight: 500;
      width: 100%;
    }
    .boxCards {
      margin: 24px 0px;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      gap: 12px;
      @media only screen and (max-width: 876px) {
        grid-template-columns: 1fr 1fr;
      }
      @media only screen and (max-width: 567px) {
        grid-template-columns: 1fr;
      }
      .card {
        box-shadow: $shadow;
        background: $white;
        border-radius: 8px;
        font-size: 14px;
        border: $border;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 12px;
        img {
          width: 75px;
        }
        @media only screen and (min-width: 1450px) {
          img {
            width: 150px;
          }
        }
        .title {
          color: $blue-primary;
          font-weight: 500;
        }
        .content {
          text-align: center;
          font-size: 14px;
        }
      }
    }
  }
}
